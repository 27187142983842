<template>
  <div v-loading="loading">
    <el-card>
      <el-switch v-model="jianyuexiangxi" active-text="简约模式" inactive-text="详细模式" @change="saveSwitchValue"></el-switch>
    </el-card>
    <div v-if="tableData && tableData.length > 0">
      <div>
        <el-table v-if="!jianyuexiangxi" max-height="100%" :data="tableData" stripe
          style="width: 100%; margin-top: 10px;">
          <el-table-column prop="id" label="隧道ID"> </el-table-column>
          <el-table-column prop="name" label="隧道名称"> </el-table-column>
          <el-table-column prop="type" label="类型"></el-table-column>
          <el-table-column prop="nport" label="内网端口"> </el-table-column>
          <el-table-column prop="dorp" label="外网端口/域名"> </el-table-column>
          <el-table-column prop="node" label="节点"> </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" style="" size="small" icon="el-icon-edit-outline"
                @click="xiugai(scope.row)">修改</el-button>
              <el-button @click="handleClick(scope.row)" type="text" size="small" icon="el-icon-view">查看</el-button>
              <el-button style="color: #F56C6C;" type="text" size="small" icon="el-icon-delete"
                @click="showDeleteDialog(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row v-if="jianyuexiangxi" :gutter="10">
        <el-col v-for="(item, index) in cardData" :key="index" :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
          <el-card :class="{ 'selected-node': selectedNodeIndex === index }" style="margin-top: 15px; position: relative;"
            shadow="hover">
            <span style="color: rgba(0, 0, 0, 0.8);">#{{ item.id }}</span>
            <el-divider direction="vertical"></el-divider>
            <span style="font-weight:bold;">{{ item.name }}</span>
            <div :style="{
              'float': 'right',
              'width': '8px',
              'height': '8px',
              'border-radius': '100%',
              'background-color': item.nodestate === 'offline' ? '#F56C6C' : (item.state === 'false' ? '#A0A0A0' : '#67C23A'),
            }"></div>
            <div style="margin-top: 10px">
              <el-tag :type="item.nodestate === 'offline' ? 'danger' : ''">
                {{ item.node }} - {{ item.type }}
              </el-tag>
            </div>
            <div style="margin-top: 10px; color: rgba(0, 0, 0, 0.8); font-size: 14px;" @click="copyToClipboard(item)">
              连接地址：{{ item.ip }}
            </div>
            <span style="font-size: 10px; color: rgba(0, 0, 0, 0.5); margin-top: 3px;">{{ item.uptime }}</span>
            <div style="margin-top: -10px;">
              <el-divider></el-divider>
            </div>
            <div style="margin-top: -10px;">
              <el-descriptions :colon=false>
                <el-descriptions-item><i class="el-icon-sort-up"></i>{{ formatData(item.today_traffic_out) }}</el-descriptions-item>
                <el-descriptions-item><i class="el-icon-sort-down"></i>{{ formatData(item.today_traffic_in) }}</el-descriptions-item>
                <el-descriptions-item label="连接数量">{{ item.cur_conns }}</el-descriptions-item>
              </el-descriptions>
            </div>
            <div style="margin-top: -20px;">
              <el-divider></el-divider>
            </div>
            <el-button-group style="width: 100%;" v-loading="loadingan">
              <el-button style="width: 33%;" type="primary" size="small" plain icon="el-icon-edit"
                @click="xiugai(item)">修改</el-button>
              <el-button style="width: 33%;" type="primary" size="small" plain icon="el-icon-view"
                @click="handleClick(item)">查看</el-button>
              <el-button style="width: 33%;" type="danger" plain size="small" icon="el-icon-delete"
                @click="showDeleteDialog(item)">删除</el-button>
            </el-button-group>
          </el-card>
        </el-col>
      </el-row>
      <el-drawer title="隧道详情" :visible.sync="dialogVisibleTunnel" direction="ltr" style="width: 150%;">
        <div v-if="tunnelDetails">
          <el-alert v-if="tunnelDetails.state === 'offline'" title="此节点已离线，暂时无法使用！" type="error" effect="dark"
            :closable="false">
          </el-alert>
          <el-alert v-if="tunnelDetails.tunnel_type === 'http' || tunnelDetails.tunnel_type === 'https'" title="注意！"
            type="warning" :description="cnameip">
          </el-alert>
          <el-divider></el-divider>
          <el-row>
            <el-col :xs="24" :sm="12">
              <div>
                <el-statistic title="节点状态">
                  <template slot="formatter">
                    <i :style="{
                      fontSize: '50px',
                      color: tunnelDetails.state === 'offline' ? '#F56C6C' : '#67C23A'
                    }" class="el-icon-finished">
                    </i>
                    <p :style="{
                      color: tunnelDetails.state === 'offline' ? '#F56C6C' : '#67C23A'
                    }">
                      {{ tunnelDetails.state }}
                    </p>
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12">
              <div>
                <el-statistic title="隧道状态">
                  <template slot="formatter">
                    <i :style="{
                      fontSize: '50px',
                      color: tunnelDetails.tunnel_state === 'false' ? '#F56C6C' : '#67C23A'
                    }" class="el-icon-switch-button">
                    </i>
                    <p :style="{
                      color: tunnelDetails.tunnel_state === 'false' ? '#F56C6C' : '#67C23A'
                    }">
                      {{ tunnelDetails.tunnel_state }}
                    </p>
                  </template>
                </el-statistic>
              </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-descriptions direction="vertical" border>
            <el-descriptions-item label="隧道ID">{{ tunnelDetails.tunnel_id }}</el-descriptions-item>
            <el-descriptions-item label="隧道名">{{ tunnelDetails.tunnel_name }}</el-descriptions-item>
            <el-descriptions-item label="类型">{{ tunnelDetails.tunnel_type }}</el-descriptions-item>
            <el-descriptions-item label="内网IP">{{ tunnelDetails.tunnel_localip }}</el-descriptions-item>
            <el-descriptions-item label="内网端口">{{ tunnelDetails.tunnel_nport }}</el-descriptions-item>
            <el-descriptions-item label="外网端口/域名">{{ tunnelDetails.tunnel_dorp }}</el-descriptions-item>
            <el-descriptions-item label="节点">{{ tunnelDetails.name }}</el-descriptions-item>
            <el-descriptions-item label="连接地址">{{ tunnelDetails.iparea }}</el-descriptions-item>
          </el-descriptions>
          <el-divider></el-divider>
          <el-descriptions direction="vertical" border>
            <el-descriptions-item label="今日上传流量">{{ formatData(tunnelDetails.today_traffic_in) }}</el-descriptions-item>
            <el-descriptions-item label="今日下载流量">{{ formatData(tunnelDetails.today_traffic_out) }}</el-descriptions-item>
            <el-descriptions-item label="当前连接数量">{{ tunnelDetails.cur_conns }}</el-descriptions-item>
            <el-descriptions-item label="使用客户端">{{ tunnelDetails.client_version }}</el-descriptions-item>
          </el-descriptions>
          <div class="top">
            <el-form ref="form" :model="form">
              <el-form-item style="line-height: 200px; text-align:center; height: 20px;">简易启动代码</el-form-item>
              <el-form-item>
                <el-input type="textarea" v-model="form.desc"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTunnel = false">关闭</el-button>
        </span>
      </el-drawer>
      <el-dialog title="注意！" :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
        <span>您现在正在删除隧道，请确认是否删除。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button :loading="loading" type="danger" @click="deleteRow">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="修改隧道" :visible.sync="xiugaiVisible">
        <el-row :gutter="15">
          <el-form ref="tunnelForm" :model="formData" :rules="rules" size="medium" label-width="100px"
            label-position="left">
            <el-col :xs="24" :sm="12">
              <el-form-item label="隧道名称" prop="name">
                <el-input v-model="formData.name" placeholder="请输入隧道名称" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item label="本地IP" prop="localip">
                <el-input v-model="formData.localip" placeholder="请输入本地IP" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item label="节点选择" prop="node">
                <div @click="openNodeDropdown" :style="{ width: '100%', cursor: 'pointer' }">
                  {{ formData.node || '请选择节点选择' }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item label="端口类型" prop="type">
                <el-select v-model="formData.type" placeholder="请选择端口类型" clearable :style="{ width: '100%' }"
                  @change="handleTypeChange">
                  <el-option v-for="(item, index) in typeOptions" :key="index" :label="item.label" :value="item.value"
                    :disabled="item.disabled"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item label="内网端口" prop="nport">
                <el-input v-model="formData.nport" placeholder="请输入内网端口" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
              <el-form-item :label="formData.type === 'http' || formData.type === 'https' ? '绑定域名' : '外网端口'" prop="dorp">
                <el-input v-model="formData.dorp" :placeholder="getPlaceholder(formData.type)" clearable
                  :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="0" :sm="24">
              <el-divider>高级设置请不要乱填，否则会导致无法启动</el-divider>
            </el-col>
            <el-col :xs="24" :sm="0">
              <el-divider>高级设置</el-divider>
            </el-col>
            <el-col>
              <el-col :xs="24" :sm="12">
                <el-form-item label="数据加密" prop="encryption">
                  <el-switch v-model="formData.encryption"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-form-item label="数据压缩" prop="compression">
                  <el-switch v-model="formData.compression"></el-switch>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="额外参数" prop="ap">
                  <el-input type="textarea" v-model="formData.ap"></el-input>
                </el-form-item>
              </el-col>
            </el-col>
          </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="xiugaiVisible = false">取 消</el-button>
          <el-button :loading="loading" type="danger" @click="xiugaisuidao">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="选择节点" :visible.sync="xznode" :before-close="handleClose">
        <el-alert style="margin-top: -30px;" title="为确保您的体验，请尽量选择负载低，距离近的节点。" type="success" show-icon>
        </el-alert>
        <div style="margin-top: 10px; display: flex;">
          <el-checkbox-group v-model="quanx">
            <el-checkbox label="UDP"></el-checkbox>
            <el-checkbox label="无权限"></el-checkbox>
          </el-checkbox-group>
          <div style="margin-left: 15px; margin-top: -4px;">
            <el-radio-group v-model="jianzhan" size="mini">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="可建站">可建站</el-radio-button>
              <el-radio-button label="不可建站">不可建站</el-radio-button>
            </el-radio-group>
          </div>
          <div style="margin-left: 15px; margin-top: -4px;">
            <el-radio-group v-model="guojia" size="mini">
              <el-radio-button label="全部">全部</el-radio-button>
              <el-radio-button label="中国">中国</el-radio-button>
              <el-radio-button label="海外">海外</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <el-row :gutter="10" v-loading="loadingjd">
          <el-col v-for="(item, index) in filteredApiData" :key="index" :xs="24" :md="12" :xl="8">
            <el-card :class="{ 'selected-node': selectedNodeIndex === index }" style="margin-top: 10px;" shadow="hover"
              @click.native="handleCardClick(index, item)">
              <span style="color: rgba(0, 0, 0, 0.8);">#{{ item.id }}</span>
              <el-divider direction="vertical"></el-divider>
              <span style="font-weight:bold;">{{ item.name }}</span>
              <el-tag v-if="item.nodegroup === 'vip'" type="warning" style="float: right; margin-top: -3px;">VIP</el-tag>
              <div style="margin-top: 16px">
                <el-tag v-if="item.udp === 'true'" effect="plain" style="border-radius: 8px;">
                  <i class="el-icon-check"></i>
                  UDP
                </el-tag>
                <el-tag v-if="item.web === 'yes'" type="success" effect="plain" style="border-radius: 8px;">
                  <i class="el-icon-connection"></i>
                  建站
                </el-tag>
                <el-tag v-if="item.fangyu === 'false'" type="warning" effect="plain" style="border-radius: 8px;">
                  <i class="el-icon-warning-outline"></i>
                  无防
                </el-tag>
              </div>
              <div style="margin-top: 16px; color: rgba(0, 0, 0, 0.7);">{{ item.notes }}</div>
            </el-card>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
    <div v-else>
      <el-empty description="还没有隧道噢"></el-empty>
    </div>
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/setToken.js";
import axios from "axios";

export default {
  data() {
    return {
      quanx: ['UDP'],
      jianzhan: '',
      guojia: '全部',
      jianyuexiangxi: true, // 默认显示模式为简约模式
      selectedNodeName: '', // 储存选中节点的名称
      xznode: false, // 节点选择对话框
      apiData: [], // 初始化节点选择的apiData
      cardData: [], // 初始化隧道列表card
      selectedNodeIndex: -1, // 初始化为-1，表示没有选中的节点
      loadingjd: false,
      loadingan: false,
      tableData: [],
      cnameip: "404",
      dialogVisible: false,
      xiugaiVisible: false,
      rowToDelete: null,
      loading: false,
      // 添加用于显示隧道详情的数据和状态
      dialogVisibleTunnel: false,
      tunnelDetails: null,
      form: {
        desc: ''
      },
      formData: {
        tunnelid: '',
        usertoken: '',
        userid: 0,
        localip: '127.0.0.1',
        name: "",
        node: "",
        type: "tcp",
        nport: 0,
        dorp: 0,
        ap: '',
        encryption: false,
        compression: false,
      },
      rules: {
        name: [{
          required: true,
          message: '请输入隧道名称',
          trigger: 'blur'
        }],
        localip: [{
          required: true,
          message: '请输入本地IP',
          trigger: 'blur'
        }],
        node: [{
          required: true,
          message: '请选择节点选择',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择端口类型',
          trigger: 'change'
        }],
        nport: [{
          required: true,
          message: '请输入内网端口',
          trigger: 'blur'
        }],
        dorp: [{
          required: true,
          message: '请输入对应数据',
          trigger: 'blur'
        }],
      },
      typeOptions: [{
        "label": "tcp",
        "value": "tcp"
      }, {
        "label": "udp",
        "value": "udp"
      }, {
        "label": "http",
        "value": "http"
      }, {
        "label": "https",
        "value": "https"
      },],
    };
  },

  computed: {
    isHttpOrHttps() {
      return this.formData.type === 'http' || this.formData.type === 'https';
    },
    isTcpOrUdp() {
      return this.formData.type === 'tcp' || this.formData.type === 'udp';
    },
    filteredApiData() {
      return this.apiData.filter(item => this.shouldDisplay(item));
    }
  },

  created() {
    this.loadSwitchValue(); // 页面加载时加载Cookie中保存的值
    this.formData.usertoken = getToken('token');
    this.getData();
    this.getData_unode();
    const usertoken = getToken('token');

    if (usertoken) {
      this.setData();
    } else {
      removeToken('token');
      this.$router.push('/login');
      this.$message({
        message: "无效的token，请重新登录",
        type: "error",
      });
    }
  },

  mounted() {
    const savedValues = localStorage.getItem('selectedValues');

    if (savedValues) {
      const parsedValues = JSON.parse(savedValues);
      this.quanx = parsedValues.quanx;
      this.jianzhan = parsedValues.jianzhan;
      this.guojia = parsedValues.guojia;
    }
  },

  methods: {
    // 带宽计算逻辑
    formatData(bytes) {
      if (bytes === '0' || bytes === '' || bytes === null || bytes === 0) return '0B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      const roundedValue = Math.round(bytes / Math.pow(k, i));
      return parseInt(roundedValue) + sizes[i];
    },

    shouldDisplay(item) {
      const usergroupIsFree = this.quanx.includes('无权限');
      const udpIsUnchecked = !this.quanx.includes('UDP');
      const jianzhanSelected = this.jianzhan !== '';

      // Determine whether to display based on the selected country
      let shouldDisplayCard = true;

      if (this.guojia === '中国') {
        shouldDisplayCard = item.china === 'yes' && item.area !== '中国香港';
      } else if (this.guojia === '海外') {
        shouldDisplayCard = item.china === 'no' || item.area === '中国香港';
      }

      const usergroup = getToken("usergroup");
      const jianzhanMatches = this.jianzhan === '可建站'
        ? item.web === 'yes'
        : this.jianzhan === '不可建站'
          ? item.web !== 'yes'
          : true;

      let shouldHideNodegroupContent = false;

      if (!usergroupIsFree && usergroup === '免费用户' && item.nodegroup === 'vip') {
        shouldHideNodegroupContent = true;
      }

      return shouldDisplayCard && !shouldHideNodegroupContent &&
        (!udpIsUnchecked || item.udp !== 'true') &&
        (!jianzhanSelected || jianzhanMatches);
    },

    copyToClipboard(item) {
      const textarea = document.createElement('textarea');
      textarea.value = item.ip;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$message.success('链接地址复制成功');
    },

    saveSwitchValue() {
      document.cookie = `switchValue=${this.jianyuexiangxi}`; // 将选择的值保存到Cookie中
    },
    loadSwitchValue() {
      const cookies = document.cookie.split(";"); // 获取所有的Cookie
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith("switchValue=")) {
          const value = cookie.substring("switchValue=".length, cookie.length);
          this.jianyuexiangxi = value === "true"; // 加载Cookie中保存的值，并设置给开关组件
          break;
        }
      }
    },

    handleCardClick(index, item) {
      // 检查用户实名状态
      const realname = getToken("realname");
      const usergroup = getToken("usergroup");

      if (realname === "未实名") {
        // 用户未实名，显示提示
        this.$notify.error({
          title: '错误',
          message: '您尚未实名，请先进行实名认证'
        });
      } else if (item.nodegroup === "vip" && usergroup === "免费用户") {
        // 节点为 VIP 节点且用户为免费用户，显示提示
        this.$notify({
          title: '警告',
          message: '此节点为VIP节点，您目前的权限组不足',
          type: 'warning'
        });
      } else {
        // 用户已实名，执行您原本的点击逻辑
        this.selectNode(index, item);
      }

      const selectedValues = {
        quanx: this.quanx,
        jianzhan: this.jianzhan,
        guojia: this.guojia
      };

      localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
      this.formData.node = this.selectedNodeName; // 将选中的节点名称赋给 formData.node
      this.xiugaiVisible = true; // 打开 dialogVisible
      this.xznode = false;
    },

    openNodeDropdown() {
      this.xznode = true;
      this.xiugaiVisible = false;
    },

    selectNode(index, item) {
      this.selectedNodeIndex = index;
      this.selectedNodeName = item.name;
    },

    getData_unode() {
      axios.get("https://cf-v1.uapis.cn/api/unode.php")
        .then((response) => {
          this.apiData = response.data;
        })
        .catch((error) => {
          console.error("Failed to fetch node data:", error);
        })
        .finally(() => {
          this.loadingjd = false;
        });
    },

    handleTypeChange() {
      if (this.isHttpOrHttps) {
        this.formData.dorp = ''; // 清空formData.dorp
      }
    },

    getPlaceholder(type) {
      return type === 'http' || type === 'https' ? '请输入绑定域名' : '请输入外网端口';
    },

    //获取userid
    async getUserId() {
      const { data: res } = await this.axios.get('userinfo.php', { params: { 'usertoken': this.formData.token } })
      this.formData.userid = res.userid
      // console.log(this.formData.userid);
    },

    setData(params) {
      const usertoken = getToken('token');
      axios
        .get('https://cf-v1.uapis.cn/api/userinfo.php', {
          params: { usertoken, ...params },
        })
        .then(response => {
          if (response.data.error) {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
              message: "无效的token，请重新登录",
              type: "error",
            });
          } else {
            const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

            setToken('userid', userid);
            setToken('username', username);
            setToken('email', email);
            setToken('qq', qq);
            setToken('usergroup', usergroup);
            setToken('bandwidth', bandwidth);
            setToken('tunnel', tunnel);
            setToken('realname', realname);
            setToken('integral', integral);
            setToken('userimg', userimg);
            setToken('term', term);
            setToken('tunnelstate', tunnelstate);
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        });
    },
    getData(params) {
      this.loading = true; // 开始表单加载状态
      const token = getToken("token");
      axios
        .get("https://cf-v1.uapis.cn/api/usertunnel.php", {
          params: { token, ...params },
        })
        .then((response) => {
          if (response.data.code !== 404) {
            // 传递参数到表单
            this.tableData = response.data;
            // 传递参数到块
            this.cardData = response.data;
          } else {
            this.tableData = [];
            this.cardData = [];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false; // 结束表单加载状态
        });
    },

    showDeleteDialog(row) {
      this.rowToDelete = row; // 保存要删除的行数据
      this.dialogVisible = true; // 显示对话框
    },

    xiugai(row) {
      const id = row.id;
      this.loadingan = true;
      axios
        .get("https://cf-v1.uapis.cn/api/tunnelinfo.php", {
          params: { id },
        })
        .then((response) => {
          if (response.data.code === 200) {
            // 设置获取的隧道详情并显示弹窗
            this.tunnelDetails = response.data;
            this.xiugaiVisible = true;

            // 修改默认设置
            this.formData.name = response.data.tunnel_name;
            this.formData.localip = response.data.tunnel_localip;
            this.formData.type = response.data.tunnel_type;
            this.formData.node = response.data.name;
            this.formData.nport = response.data.tunnel_nport;
            this.formData.dorp = response.data.tunnel_dorp;
            this.formData.encryption = response.data.tunnel_encryption;
            this.formData.compression = response.data.tunnel_compression;
            this.formData.ap = response.data.tunnel_ap;
            this.formData.tunnelid = row.id;

            this.form.desc = './frpc.exe -u ' + getToken('token') + ' -p ' + this.tunnelDetails.tunnel_id;
          } else {
            this.$message({
              message: response.data.error,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error("请求API时发生错误:", error);
          this.$message({
            message: error,
            type: "error",
          });
        })
        .finally(() => {
          this.loadingan = false;
        });
    },

    deleteRow() {
      if (this.rowToDelete) {
        this.loading = true; // 设置确认按钮为加载状态
        // 执行删除操作
        this.del(this.rowToDelete);
      }
      this.dialogVisible = false; // 关闭对话框
    },
    del(row) {
      const token = getToken("token");
      const nodeid = row.id;
      const userid = row.userid;
      axios
        .get("https://cf-v1.uapis.cn/api/deletetl.php", {
          params: { token, nodeid, userid },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({ message: "删除成功", type: "success" });
            this.getData();
          }
        })
        .finally(() => {
          this.loading = false; //结束确认按钮加载状态
        });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    // 添加处理"查看"按钮点击事件的方法
    handleClick(row) {
      this.loadingan = true;
      const id = row.id;
      axios
        .get("https://cf-v1.uapis.cn/api/tunnelinfo.php", {
          params: { id },
        })
        .then((response) => {
          if (response.data.code === 200) {
            // 设置获取的隧道详情并显示弹窗
            this.tunnelDetails = response.data;
            this.cnameip = "如果您的隧道类型为http或https，则您需要将您绑定的域名通过CANME解析到：" + response.data.ip;
            this.dialogVisibleTunnel = true;
            this.form.desc = './frpc.exe -u ' + getToken('token') + ' -p ' + this.tunnelDetails.tunnel_id;
          } else {
            this.$message({
              message: response.data.error,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error("请求API时发生错误:", error);
          this.$message({
            message: error,
            type: "error",
          });
        })
        .finally(() => {
          this.loadingan = false;
        });
    },

    xiugaisuidao() {
      this.$refs['tunnelForm'].validate(valid => {
        if (!valid) return
        this.add()
      })
    },
    async add() {
      this.loading = true;
      this.$refs['tunnelForm'].validate(async valid => {
        if (!valid) return;

        if (this.isHttpOrHttps) {
          const domainNamePattern = /^([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})$/i;
          if (!domainNamePattern.test(this.formData.dorp)) {
            this.$message.error('请输入有效的域名');
            this.loading = false;
            return;
          }
        } else if (this.isTcpOrUdp) {
          const port = parseInt(this.formData.dorp);
          if (isNaN(port) || port < 10000 || port > 65535) {
            this.$message.error('请输入10000~65535之间的数字');
            this.loading = false;
            return;
          }
        }

        const requestData = {
          ...this.formData,
          encryption: this.formData.encryption ? 'true' : 'false',
          compression: this.formData.compression ? 'true' : 'false',
        };

        try {
          const { data: res } = await this.axios.post('/cztunnel.php', requestData);

          if (res.code === 200) {
            location.reload();
            this.$message.success(res.error);
          } else {
            this.$message.error(res.error);
          }

          this.loading = false;
          this.onClose();
          this.close();
        } catch (error) {
          console.error('Error:', error);
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  margin-top: 20px
}

.selected-node {
  color: #40a0ff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s ease;
}
</style>